import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import dayjs from 'dayjs';
import { tap } from 'rxjs';
import { RegionSelectorComponent } from 'src/app/components/region-selector/region-selector.component';
import { AbstractSearch } from 'src/lib/components/abstract/abstract.search';
import { DATE_FORMAT } from 'src/lib/utils';
import { DialogService } from '../../services/dialog.service';

export interface HotelGoodsSearchParams {
  checkInDt: string;
  checkOutDt: string;
  nights: number;
  roomCnt: number;
  tourNumber: number;
}

@Component({
  selector: 'trnty-hotel-goods-search',
  templateUrl: './hotel-goods-search.component.html',
  styleUrls: ['./hotel-goods-search.component.scss'],
})
export class HotelGoodsSearchComponent
  extends AbstractSearch
  implements OnInit
{
  override formGroup = new FormGroup({
    tourRegionCode: new FormControl<string>(null!),
    checkInDt: new FormControl<string>(
      dayjs().add(1, 'day').format(DATE_FORMAT),
      [Validators.required],
    ),
    // checkOutDt: [
    //   dayjs().add(2, 'day').format(DATE_FORMAT),
    //   Validators.required,
    // ],
    nights: new FormControl<number>(1, {
      nonNullable: true,
      validators: [Validators.min(1)],
    }),
    roomCnt: new FormControl<number>(1, {
      nonNullable: true,
      validators: [Validators.min(1)],
    }),
    tourNumber: new FormControl<number>(2, {
      nonNullable: true,
      validators: [Validators.min(1)],
    }),
  });
  override resetValue: any = {
    checkInDt: dayjs().add(1, 'day').format(DATE_FORMAT),
    // checkOutDt: dayjs().add(2, 'day').format(DATE_FORMAT),
    nights: 1,
    roomCnt: 1,
    tourNumber: 2,
  };

  /**
   * 지역 선택중 여부
   */
  isRegionSelecting = false;

  /**
   * 체크인 일자 선택 가능 여부
   */
  @Input() hasCheckInDt = true;

  /**
   * 지역 선택 가능 여부
   */
  @Input() hasTourRegionCode = true;

  /**
   * 객실 수 선택 가능 여부
   */
  @Input() hasRoomCnt = false;

  /**
   * 초기화 버튼 여부
   */
  @Input() hasReset = true;

  constructor(
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
  ) {
    super();
  }

  ngOnInit(): void {}

  override getValue(): HotelGoodsSearchParams {
    // const { checkInDt, checkOutDt, ...rest } = this.formGroup.getRawValue();
    // const nights = dayjs(checkOutDt).diff(checkInDt, 'day');
    // const value = {
    //   ...rest,
    //   checkInDt: dayjs(checkInDt).format(DATE_FORMAT),
    //   checkOutDt,
    //   nights,
    // };

    const { checkInDt, nights, roomCnt, tourNumber } =
      this.formGroup.getRawValue();
    const checkOutDt = dayjs(checkInDt).add(nights, 'day').format(DATE_FORMAT);
    const value = {
      checkInDt: dayjs(checkInDt).format(DATE_FORMAT),
      checkOutDt,
      nights: Number(nights),
      roomCnt: Number(roomCnt),
      tourNumber: Number(tourNumber),
    };

    return value;
  }

  override setValue(value: any): void {
    const { nights, ...rest } = value;

    let checkInDt: string;
    let checkOutDt: string;

    if (value?.checkInDt) {
      checkInDt = dayjs(value?.checkInDt).format(DATE_FORMAT);
    } else {
      checkInDt = dayjs().add(1, 'day').format(DATE_FORMAT);
    }

    if (value?.nights) {
      checkOutDt = dayjs(checkInDt).add(nights, 'day').format(DATE_FORMAT);
    } else {
      checkOutDt = dayjs(checkInDt).add(1, 'day').format(DATE_FORMAT);
    }

    const newValue = { checkInDt, checkOutDt, ...rest };
    this.formGroup.patchValue(newValue);
  }

  /**
   * 지역 선택 클릭시 (검색)
   */
  onRegionItemClick(): void {
    this.isRegionSelecting = true;
    this.dialogService
      .open(RegionSelectorComponent)
      .onDialogClosed.pipe(
        tap((region) => {
          if (region) {
            this.onRegionChange(region);
          }
        }),
      )
      .subscribe();
  }

  /**
   * 지역 선택기 닫기 클릭시 (검색)
   */
  onRegionSelectorCloseClick(event: Event): void {
    event.stopPropagation();
    this.isRegionSelecting = false;
  }

  /**
   * 지역 변경시 (검색)
   */
  onRegionChange(region: string): void {
    this.formGroup.get('tourRegionCode')?.setValue(region);
    this.isRegionSelecting = false;
  }
}
