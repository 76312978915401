<form [formGroup]="formGroup">
  <div class="row g-3 align-items-end">
    @if (hasTourRegionCode) {
      <!-- 목적지 -->
      <div class="col" (click)="onRegionItemClick()">
        <label class="form-label">{{ 'Destination' | translate }}</label>

        <div class="form-select">
          {{ formGroup.value.tourRegionCode || 'Search_Dest' | translate }}
        </div>
      </div>
    }

    @if (hasCheckInDt) {
      <!-- 시작일 -->
      <div class="col">
        <label for="checkInDt" class="form-label">체크인 일자</label>
        <input
          class="form-control"
          id="checkInDt"
          type="date"
          formControlName="checkInDt"
        />
      </div>
    }

    <!-- 박 수 -->
    <div class="col">
      <label for="nights" class="form-label">숙박 일수</label>
      <input
        type="number"
        class="form-control"
        id="nights"
        formControlName="nights"
      />
    </div>

    <!-- 종료일 -->
    <!-- <div class="col">
      <label for="checkOutDt" class="form-label">체크아웃 일자</label>
      <input
        class="form-control"
        id="checkOutDt"
        type="date"
        formControlName="checkOutDt"
        [min]="formGroup.value.checkInDt"
      />
    </div> -->

    <!-- 인원 수 -->
    <div class="col">
      <label for="tourNumber" class="form-label">인원 수</label>
      <input
        type="number"
        class="form-control"
        id="tourNumber"
        formControlName="tourNumber"
      />
    </div>

    @if (hasRoomCnt) {
      <!-- 객실 수 -->
      <div class="col">
        <label for="roomCnt" class="form-label">객실 수</label>
        <input
          type="number"
          class="form-control"
          id="roomCnt"
          formControlName="roomCnt"
        />
      </div>
    }

    <!-- 검색 버튼 -->
    <div class="col-auto">
      <button class="btn btn-primary mt-3 me-2" (click)="onSearchClick()">
        검색
      </button>

      @if (hasReset) {
        <button class="btn btn-secondary mt-3" (click)="onResetClick()">
          초기화
        </button>
      }
    </div>
  </div>
</form>
