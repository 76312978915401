import { Directive, EventEmitter, Output } from '@angular/core';
import { AbstractForm } from './abstract.form';

/**
 * 검색 컴포넌트 추상클래스
 */
@Directive()
export abstract class AbstractSearch extends AbstractForm {
  /**
   * 검색조건 초기화시 입력할 값
   */
  resetValue: any;

  /**
   * 검색 클릭시 이벤트
   */
  @Output() searchClick: EventEmitter<any> = new EventEmitter();

  /**
   * 초기화 클릭시 이벤트
   */
  @Output() resetClick: EventEmitter<any> = new EventEmitter();

  /**
   * 검색 클릭시
   */
  onSearchClick(): void {
    this.searchClick.emit(this.getValue());
  }

  /**
   * 검색 클릭시
   */
  onResetClick(): void {
    this.formGroup.reset(this.resetValue);
    this.resetClick.emit(this.getValue());
  }
}
