import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RegionSelectorModule } from '../region-selector/region-selector.module';
import { SharedModule } from '../shared/shared.module';
import { HotelGoodsSearchComponent } from './hotel-goods-search.component';

@NgModule({
  declarations: [HotelGoodsSearchComponent],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    RegionSelectorModule,
  ],
  exports: [HotelGoodsSearchComponent],
})
export class HotelGoodsSearchModule {}
